import React, { useMemo } from "react";
import { IPayment } from "..";
import { KeyValueRow } from "sharedComponents/dataDisplay/keyValueRow";
import { formatOrderDate } from "features/groupTabs/components/OrderItemsSection";
import { Spinner, DateFormatted, Collapse, Currency, Text } from "sharedComponents";
import "../assets/TransactionTab.scss";
import { NoData } from "../../party/components/NoData";

export interface Props {
    loading: boolean;
    payments?: IPayment[];
    currency?: string;
    locale?: string;
}

const isRefund = (payment: IPayment) => {
    return payment?.refundId !== null;
};

export const TransactionTab = ({ loading, payments, locale, currency }: Props) => {
    const sortedPayments = useMemo(
        () => payments?.slice().sort((a, b) => b.dateCreated.localeCompare(a.dateCreated, locale)),
        [payments, locale]
    );

    return loading ? (
        <Spinner size="large" />
    ) : (
        <>
            {!sortedPayments ? (
                <NoData />
            ) : (
                <Collapse>
                    {sortedPayments.map((payment, index) => (
                        <Collapse.Panel
                            key={`transaction_row_${payment.transactionId}_${index}`}
                            header={
                                <div className="transation-info">
                                    <KeyValueRow
                                        className="transation-info__date"
                                        item={{
                                            label: <Text mode="bold" value={formatOrderDate(payment.dateCreated)} />,
                                        }}
                                    />
                                </div>
                            }
                        >
                            <div className="groupTab-transaction">
                                <KeyValueRow
                                    item={{
                                        label: "Amount added",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={payment.amount || 0}
                                            />
                                        ),
                                    }}
                                />

                                {isRefund(payment) && (
                                    <>
                                        <KeyValueRow
                                            item={{
                                                label: "Refunded",
                                                value: (
                                                    <Currency
                                                        locale={locale!}
                                                        currency={currency!}
                                                        value={0 - (payment?.refundedAmount || 0)}
                                                    />
                                                ),
                                            }}
                                        />
                                        <KeyValueRow
                                            item={{
                                                label: "Processing fee",
                                                value: (
                                                    <Currency
                                                        locale={locale!}
                                                        currency={currency!}
                                                        value={payment?.processingFee || 0}
                                                    />
                                                ),
                                            }}
                                        />
                                    </>
                                )}
                                <KeyValueRow
                                    item={{
                                        label: "Total Charged",
                                        value: (
                                            <Currency
                                                locale={locale!}
                                                currency={currency!}
                                                value={payment.transactionTotal}
                                            />
                                        ),
                                    }}
                                />
                                <KeyValueRow
                                    item={{
                                        label: "Transaction ID",
                                        value: (
                                            <Text mode={["link", "bold"]}>
                                                <a
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    href={payment.transactionLink}
                                                >
                                                    {payment.transactionId || ""}
                                                </a>
                                            </Text>
                                        ),
                                    }}
                                />

                                {isRefund(payment) && (
                                    <KeyValueRow
                                        item={{
                                            label: "Refund transaction ID",
                                            value: (
                                                <Text mode={["link", "bold"]}>
                                                    <a
                                                        target="_blank"
                                                        rel="noopener noreferrer"
                                                        href={payment?.refundLink}
                                                    >
                                                        {payment?.refundId}
                                                    </a>
                                                </Text>
                                            ),
                                        }}
                                    />
                                )}

                                <KeyValueRow item={{ label: "Payment method", value: payment.paymentMethod }} />
                                <KeyValueRow
                                    item={{ label: "Date", value: <DateFormatted value={payment.dateCreated} /> }}
                                />
                                <KeyValueRow item={{ label: "Status", value: payment.status }} />
                            </div>
                        </Collapse.Panel>
                    ))}
                </Collapse>
            )}
        </>
    );
};
