import React, { useEffect } from "react";
import { Party, Refund } from "../types";
import { Text, Section, Spinner, DateFormatted } from "sharedComponents";
import { InfoSection } from "sharedComponents/dataDisplay/infoSection";
import { Item } from "sharedComponents/dataDisplay/item";
import { NoData } from "./NoData";
import "../assets/OrderDetailsSection.scss";
import { region } from "features/region";
import { getPartyOrderingFlow } from "features/location/selectors";

export interface Props {
    party?: Party;
    memberId?: string;
    memberEmail?: string;
    isGuest: boolean;
    loading: boolean;
    partyId?: string;
    locationId: string;
    refund?: Refund;
    isPayAndOrder: boolean;
    getParty: (locationId: string) => void;
    getMembers: () => void;
}

export const OrderDetailsSection = ({
    party,
    memberId,
    isGuest,
    loading,
    partyId,
    locationId,
    refund,
    isPayAndOrder,
    getParty,
    getMembers,
}: Props) => {
    useEffect(() => {
        if (!locationId) {
            getMembers();
        } else {
            getParty(locationId);
        }
    }, [getParty, getMembers, partyId, locationId]);

    const orderTypeLabel = party?.isTakeaway ? "Takeaway" : "Dine-in";
    const sectionLabel = party?.section?.displayName || "-";
    const orderingFlowLabel = party && getPartyOrderingFlow(party.type, party.isFlexTab);

    return (
        <Section className="order-details">
            {loading ? (
                <Spinner size="large" />
            ) : !party ? (
                <NoData title="Party Details" />
            ) : (
                <InfoSection title={"Party Details"}>
                    <>
                        <Item label="Party ID">
                            <Text preset="subtitle" mode="bold" value={party.tableNumber} />
                        </Item>
                    </>
                    <>
                        <Item label="Submitted">
                            {party.dateSubmitted ? (
                                <Text mode="bold">
                                    <DateFormatted value={party.dateSubmitted} />
                                </Text>
                            ) : (
                                "-"
                            )}
                        </Item>
                        <Item label="Venue">
                            <Text mode={["link", "bold"]}>
                                <a href={`/${region.getActiveRegion().id}/location/${party.location.id}/1`}>
                                    {party.location.displayName}
                                </a>
                            </Text>
                        </Item>

                        <Item label="Ordering Flow" value={`${orderingFlowLabel}, ${orderTypeLabel}`} />
                        <Item label="Section" value={sectionLabel} />

                        {party.isTakeaway && party.dateScheduled && (
                            <Item label="Collection">
                                <Text mode="bold">
                                    <DateFormatted value={party.dateScheduled} />
                                </Text>
                            </Item>
                        )}

                        {party.groupTab && (
                            <Item label="Group Tab name">
                                <Text mode={["link", "bold"]}>
                                    <a
                                        href={`/${region.getActiveRegion().id}/location/${locationId}/1/grouptab/${
                                            party.groupTab.id
                                        }`}
                                    >
                                        {party.groupTab.tabName}
                                    </a>
                                </Text>
                            </Item>
                        )}
                    </>
                    <>
                        <Item label="Order Status">
                            <Text mode="bold" className={party.closeReasonSeverity.toLowerCase()}>
                                <p>{party.closeReason}</p>
                            </Text>
                        </Item>
                        {!!refund && (
                            <Item label="Refund Status">
                                <Text mode="bold" className={refund.statusSeverity.toLowerCase()}>
                                    <p>
                                        {refund.status} ({<DateFormatted value={refund.date} />})
                                    </p>
                                </Text>
                            </Item>
                        )}
                        <Item label="Party ID (internal)" value={party.id}></Item>
                        {isPayAndOrder &&
                            (isGuest ? (
                                <Item label="Customer ID">
                                    <Text>{memberId}</Text>
                                </Item>
                            ) : (
                                <Item label="Customer ID">
                                    <Text mode={["link", "bold"]}>
                                        <a href={`/${region.getActiveRegion().id}/member/${memberId}/1`}>{memberId}</a>
                                    </Text>
                                </Item>
                            ))}
                        {party.groupTab && (
                            <Item label="Menu type">
                                <Text mode="bold">
                                    <p>
                                        {!party.groupTab?.packageName
                                            ? "Full menu"
                                            : "Package: " + party.groupTab?.packageName}
                                    </p>
                                </Text>
                            </Item>
                        )}
                    </>
                </InfoSection>
            )}
        </Section>
    );
};
